@tailwind base;
@tailwind components;
@tailwind utilities;

/* 全局样式 */
body {
  @apply bg-darkBackground text-darkText; /* Tailwind CSS 深色背景和文字颜色 */

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 代码块样式 */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #d1d5db; /* 浅灰色文字，适合深色背景 */
}

/* 深色主题下的日历样式 */
.custom-calendar {
  @apply bg-darkCard text-darkText;
}

.custom-calendar .react-calendar__tile {
  background-color: #16213e;
  color: #e0e0e0;
  border: 1px solid #1f4068;
}

.custom-calendar .react-calendar__tile--active {
  background-color: #0f3460;
  color: #fff;
}

.custom-calendar .react-calendar__tile--now {
  background-color: #3282b8;
  color: #fff;
}

.custom-calendar .react-calendar__month-view__weekdays {
  color: #e0e0e0;
}

.custom-calendar .react-calendar__navigation button {
  color: #e0e0e0;
}
