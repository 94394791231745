/* src/pages/CourseSelection.css */

/* 样式化下拉菜单 */
select {
  appearance: none;
  background-color: #4A5568; /* Tailwind 的 gray-700 */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='10' height='5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0l5 5 5-5H0z' fill='%23BEE3F8'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 5px;
  padding-right: 30px;
  cursor: pointer;
  color: #EDF2F7; /* Tailwind 的 gray-200 */
}

/* 去除下拉菜单的默认轮廓并添加自定义聚焦效果 */
select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.6); /* Tailwind 的 blue-400 */
}

/* 移除下拉菜单的默认箭头（针对某些浏览器） */
select::-ms-expand {
  display: none;
}

/* 样式化清除按钮 */
button {
  background-color: #E53E3E; /* Tailwind 的 red-600 */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #C53030; /* Tailwind 的 red-700 */
}

/* 响应式设计 */
@media (max-width: 768px) {
  .flex {
    flex-direction: column;
  }

  /* 移除 .w-1/2 类的样式 */
  /* 如果之前有定义，确保删除或注释掉 */
}

/* 优化滚动条样式（可选） */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #2D3748; /* Tailwind 的 gray-700 */
}

::-webkit-scrollbar-thumb {
  background-color: #4A5568; /* Tailwind 的 gray-700 */
  border-radius: 4px;
  border: 2px solid #2D3748; /* Tailwind 的 gray-700 */
}

/* 课程列表项样式 */
ul li {
  padding: 0.5rem;
  background-color: #2D3748; /* Tailwind 的 gray-700 */
  border-radius: 0.375rem;
  transition: background-color 0.2s;
}

ul li:hover {
  background-color: #4A5568; /* Tailwind 的 gray-600 */
}

/* 搜索框输入光标颜色 */
input::placeholder {
  color: #A0AEC0; /* Tailwind 的 gray-400 */
}
